html {
    margin: 0;
    padding: 0;
}

.App {
    font-family: sans-serif;
    text-align: center;
}

* > body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
